@import "@angular/cdk/overlay-prebuilt.css";
@import "definitions";
@import "includes/index";

.logo-gli-self-service__bg--transparent {
    background-image: url("../images/user-self-service.svg");
}

.mt-1 {
    margin-top: 1em;
}

.mb-1 {
    margin-bottom: 1em;
}

.d-block {
    display: block;
}

.scrollable-dialog {
    padding: $spacing3;
    max-height: calc(100vh - 8em);
}

.organization-info-container {
    padding: 0 2em;
    margin: 0 auto;
}

.info-container {
    &--flex {
        display: flex;
    }

    &__row {
        margin-bottom: 1em;
    }
    &__column {
        width: 100%;
        line-height: 2;
    }

    &__row,
    &__column {
        &--label,
        &--value {
            vertical-align: top;
            display: inline-block;
            box-sizing: border-box;
            line-height: 1.2;
        }

        &--label {
            width: 10em;
        }

        &--value {
            width: calc(100% - 10em);
            font-weight: 700;
            color: rgb(70, 70, 70);
        }
    }
}

.table-header-flex {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button--success {
    background-color: $ok-color;
    color: white;
}

.button--success:hover {
    background-color: $ok-color;
    color: white;
}

.transparent-background {
    background: transparent !important;
    color: #777777 !important;
}

.transparent-background:hover {
    border: 1px solid #777777 !important;
}

.invitation-link {
    background: #eef9f9;
    color: #00747c;
    line-height: 2.5;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: larger;
}

.copy-icon {
    width: 40px;
    height: 40px;
}

.footer-button {
    display: inline-block;
    width: 100px;
}

.user-info-header {
    background-color: #f2faff;
    height: 40px;
    width: 100%;

    &__buttons {
        margin-left: 20px;
    }
}

.add-contact-row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__column {
        width: 47%;
        margin-bottom: 10px;
    }

    input {
        width: 100%;
    }
}

.input-label {
    display: inline-block;
    margin-bottom: 5px;
    color: rgb(92, 92, 92);
}

.access-denied-container {
    width: 60%;
    margin: 5rem auto;
    text-align: center;

    h1 {
        font-size: 3rem;
    }

    p {
        font-size: 1.5rem;
    }

    .logout-box {
        border: 1px solid #00747c;
        background-color: #f2faff;
        border-radius: 3px;
        padding: 0.5rem 0.75rem;
        max-width: 600px;
        margin: 2rem auto;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.docusign-container {
    width: 60%;
    margin: 5rem auto;
    text-align: center;

    h1 {
        font-size: 3rem;
    }

    p {
        font-size: 1.5rem;
    }
}

.input-error {
    color: red;
}

.manual-input {
    width: 48%;
    margin-right: 2%;
}

.note {
    font-style: italic;
}

.table__row .button--condensed {
    white-space: nowrap;
}

.table__row:hover .button--status,
.table__row:hover .button--accept {
    background-color: #00a0aa;
    color: #ffffff;
}

.table__row:hover .button--refuse {
    background-color: rgb(185, 0, 0);
    color: #ffffff;
}

.lg-reference-dropdown {
    &--button-icon {
        .lg-dropdown__icons {
            lg-icon {
                width: 24px;
                height: 24px;
                color: inherit;
                vertical-align: middle;
                margin-left: -4px;
                margin-right: 2px;
            }
        }
    }
}
.lg-dropdown-popup--no-icons {
    .lg-icon {
        display: none;
    }
}

.lg-pivot-table__row--tips {
    .lg-pivot-table__row--tip {
        position: absolute;
        top: $spacing2;
        display: inline-block;

        &--right-button {
            right: $spacing9;
            text-align: right;

            .lg-icon {
                transform: scaleX(-1);
            }
        }

        &--organization-button {
            left: 125px;
            text-align: left;
        }

        .lg-pivot-table__row--tip_text {
            display: inline-block;
            vertical-align: top;
            transform: translate(0, -50%);
            margin: 32px $spacing3 0 $spacing3;
            max-width: 120px;
        }
    }
}
