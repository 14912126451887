.docusign {
    margin: 50px;

    color: #47474a;
    line-height: 32px;
    font-size: 20px;
}

.docusign__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

.docusign__logo {
    width: 149px;
    height: 44px;
    background: transparent url("../../../images/mrdm-temp.png") no-repeat top left;
}

.docusign__learn {
    margin-left: auto;
    a {
        margin-left: 1ex;
    }
}

.docusign__body {
    margin: 0 auto;
    max-width: 720px;

    h1 {
        color: black;
        font-size: 150%;
    }
}
