@use "sass:math";

.app-form-box {
    border: 1px solid $gray-color-10;
    border-radius: 4px;
    padding: 30px;
    margin: 30px 0;

    h2 {
        font-size: 24px;
        color: #313234;
        &:first-of-type {
            margin-top: 0;
        }
    }
}

$gutter: 16px;

.app-form-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    box-sizing: border-box;
    margin: 16px math.div($gutter, -2);
    width: calc(100% + #{$gutter});
    line-height: 32px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.app-form-cell {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 math.div($gutter, 2);

    &--no-padding {
        padding: 0;
    }

    &--half {
        flex-basis: 50%;
        max-width: 100%;
    }

    &--horizontal {
        flex-direction: row;
        align-items: center;

        .app-form-cell__error {
            margin-top: 0 !important;
        }
    }

    &--info {
        margin-top: 8px;
    }

    .button {
        width: 100%;
    }

    .app-form-cell__error {
        color: red;
        margin-top: 8px;
    }

    .lg-styled-radio {
        position: relative;
        top: 5px;
    }
}

.app-form-label {
    margin: 8px 0;
    &--active {
        color: #00a0aa;
    }
    &--inline {
        margin-right: 1em;
    }
}

.app-form-cell__radio-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1em;
    line-height: 1.2;
    .lg-styled-radio {
        top: 4px;
    }
}

.app-form-cell__input-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    input {
        flex-grow: 1;
    }
    & > *:not(:first-child) {
        margin-left: 16px;
    }
}

.valid_agb_code {
    border-radius: 4px;
    background-color: #dcf4f3;
    color: #2a9c6b;
    padding: 9px 24px 9px 12px;
    text-align: center;
    width: 150px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    & > div {
        flex-grow: 1;
    }

    .lg-icon {
        flex-grow: 0;
        flex-shrink: 0;
    }
}
